.reset-password-main {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  background-color: var(--background-color);
  padding: 10vh 0;
  overflow: hidden;
  .reset-password-one {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0% 10%;
    img {
      height: 7vh;
    }
    div {
      width: 100%;
      border-bottom: 1px solid var(--border-color);
      margin: 8vh 0;
      input {
        border: 0;
        background-color: transparent;
        height: 5vh;
        width: 100%;
      }
    }
    button {
      background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
      width: 70%;
      height: 5vh;
      font-weight: 600;
      border: 0;
      color: var(--background-color);
    }
  }
  .reset-password-two {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0% 5%;
    position: relative;
    align-items: center;
    .r-p-two-footer {
      width: 40%;
      height: 6vh;
      background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
      cursor: pointer;
      color: var(--background-color);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      transform: translateY(100%);
      z-index: 1;
    }
    img {
      height: 7vh;
      margin-bottom: 5vh;
    }
    .r-p-two-otp-wrapper {
      width: 100%;
      p {
        text-align: center;
        width: 100%;
        font-size: 90%;
      }
      div {
        width: 100%;
        justify-content: space-between;
        padding: 0 10%;
        div {
          padding: 0;
          width: 40px;
          input {
            width: 40px !important;
            height: 40px;
            font-size: 22px !important;
            border: 0;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .reset-password-three {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0% 10%;
    position: relative;
    img {
      height: 7vh;
      margin-bottom: 5vh;
    }
    button {
      width: 60%;
      background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
      border: 0;
      color: var(--background-color);
      padding: 6px 0;
      font-size: 90%;
      font-weight: 600;
    }
    .r-s-two-enter-password {
      width: 100%;
      border-bottom: 1px solid var(--border-color);
      position: relative;
      margin: 3vh 0;
      &:nth-child(3) {
        margin-bottom: 5vh;
      }
      p {
        text-align: left;
        margin: 0;
      }
      input {
        background-color: transparent;
        border: 0;
        width: 100%;
        height: 5vh;
      }
      span {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        position: absolute;
        right: 10px;
        bottom: 2.5vh;
        transform: translateY(50%);
      }
      .valid {
        background-color: var(--green-color);
      }
      .invalid {
        background-color: var(--red-color);
      }
    }
    .password-requirement {
      position: absolute;
      left: 0;
      width: 100%;
      height: 6vh;
      top: calc(100% + 4vh);
      color: var(--main-color);
      transition: 0.3s;
      border-top: 1px solid var(--main-color);

      .p-r-body {
        height: 0;
        overflow: hidden;
      }
      .p-r-header {
        width: 100%;
        height: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        color: var(--main-color);
      }
    }
    .full-width {
      top: -10vh;
      height: calc(100% + 10vh);
      transition: 0.3s;
      background-color: white;
      .p-r-header {
        color: initial;
        border-bottom: 1px solid var(--border-color);
      }
      .p-r-body {
        height: calc(100% - 6vh);
        padding: 5%;
        display: block;
        li {
          font-size: 95%;
          margin-bottom: 1vh;
        }
      }
    }
  }
  .reset-password-four {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    padding: 0 10%;
    img {
      height: 7vh;
      margin-bottom: 5vh;
    }
    p,
    h5 {
      margin: 0;
      text-align: center;
    }
    h5 {
      font-weight: 700;
      margin-bottom: 5vh;
    }
  }
}
