.split-table-main {
  width: 100%;
  height: 100%;
  border-left: 1px solid var(--border-color);
  .split-asset-header {
    height: 40px;
    width: 100%;
    background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
    display: flex;
    padding: 0 4vh;
    h6 {
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      width: calc(100% / 4);
      font-size: 85%;
      font-weight: 600;
      color: var(--background-color);
      &:nth-child(3) {
        justify-content: center;
      }
      &:last-child {
        justify-content: center;
      }
    }
  }
  .split-asset-body {
    height: calc(100% - 90px);
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .empty-list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .single-transaction {
      height: 70px;
      width: 100%;
      display: flex;
      padding: 0 4vh;
      border-bottom: 1px solid var(--border-color);
      cursor: pointer;
      h6 {
        font-size: 90%;
        width: calc(100% / 4);
        display: flex;
        align-items: center;

        img {
          height: 20px;
          width: 20px;
          margin-right: 10px;
          border-radius: 50%;
        }
        button {
          font-size: 80%;
          display: flex;
          align-items: center;
          padding: 5px 10px;
          background-color: var(--light-border-color);
          border: 0;
          font-weight: 500;
          img {
            height: 18px;
            width: 18px;
          }
        }
        &:nth-child(2) {
          button {
            margin-left: 10px;
            font-size: 65%;
            background-color: transparent;
            border: 1px solid var(--border-color);
            &:hover {
              transform: scale(1.1);
              transition: 0.3s;
            }
          }
        }
        &:nth-child(3) {
          justify-content: center;
        }
        &:last-child {
          justify-content: center;
        }
      }
    }
  }
  .split-asset-footer {
    background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
    height: 50px;
    display: flex;
    padding: 0 4vh;
    padding-right: 0;
    align-items: center;
    justify-content: space-between;
    h6 {
      color: var(--background-color);
      margin-bottom: 0;
      &:last-child {
        height: 100%;
      }
    }
    button {
      background-color: transparent;
      border: 0;
      width: 80px;
      height: 50px;
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}
