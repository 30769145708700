.manual-loading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        height: 20%;
        aspect-ratio: 1;
        animation: pulsating 1.4s infinite linear;
    }
}
@keyframes pulsating{
    0%{
        transform: scale(1);
        opacity: 0.5;
    }
    50%{
        transform: scale(1.1);
        opacity: 1;
    }
    100%{
        transform: scale(1);
        opacity: 0.5;
    }
}