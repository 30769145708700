html {
  --main-color: #191F41;
  --green-color: #2ea654;
  --red-color: #da4040;
  --light-color: #ffffff;
  --border-color: #e5e5e5;
  --light-border-color: #f1f1f1;
  --nvest-color: #002a51;
  --background-color: #ffffff;
  --gray-color: #7b7b7b;
  --text-color: #000000;
}
html[data-theme="dark"] {
  --main-color: #dbdbdb;
  --green-color: #2ea654;
  --red-color: #da4040;
  --light-color: #ffffff;
  --border-color: #727272;
  --light-border-color: #494949;
  --nvest-color: #002a51;
  --background-color: #1b1b1b;
  --gray-color: #7b7b7b;
  --text-color: #ffffff;
}
