body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

div {
  box-sizing: border-box;
}
code {
  font-family: "Montserrat", sans-serif !important;
}
