.bankers-main {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  background-color: var(--background-color);
  padding: 10vh 0;
  padding-bottom: 0;
  .title-logo {
    width: 100%;
    img {
      height: 6vh;
    }
    margin-bottom: 4vh;
  }
  .bankers-list {
    padding: 0 8vh;
    width: 100%;
    .bankers-search {
      width: 100%;
      input {
        width: 100%;
        padding: 10px;
        border: 1px solid var(--border-color);
      }
    }
    .banker-list-wrapper {
      height: 40vh;
      width: 100%;
      overflow: auto;
      &::-webkit-scrollbar{
          width: 0;
      }
      .banker-list-loader{
          display: flex;
          align-items: center;
          height: 60px;
          p{
              margin: 0; 
              color: var(--border-color);
              background-color: var(--border-color);
              animation: glow-dim 1.5s infinite;
          }
          button{
              height: 30px;
              width: 30px;
              border-radius: 50%;
              margin-right: 10px; background-color: var(--border-color);
              animation: glow-dim 1.5s infinite;
              border: 0;
          }

      }
      .single-banker{
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid var(--border-color);
        cursor: pointer;
        &:hover{
            background-color: var(--light-border-color);
        }
        h6{
            margin-bottom: 0;
            img{
                height: 25px;
                width: 25px;
                margin-right: 10px;
            }
        }

      }
    }
  }
}
