.two-fa-main{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    background-color: var(--background-color);
    padding: 10vh 0;
    ._2fa-loading{
        width: 100%;
        height: 30vh;

    }
    img{
        height: 7vh;
        margin-bottom: 5vh;
    }
    .ot-input-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
            text-align: center;
            font-size: 90%;
            font-weight: 600;
        }
        div {
            width: 100%;
            justify-content: space-between;
            // padding: 0 10%;
            div {
                padding: 0;
                width: 40px;
                input {
                    width: 40px !important;
                    height: 40px;
                    font-size: 22px !important;
                    font-weight: 600 !important;
                    border: 0;
                    border-radius: 3px;
                    &:focus{
                        transition: 0.3s;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);

                    }
                    transition: 0.3s;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
    .error-input{
        input{
            border: 1px solid var(--red-color) !important;
            color: var(--red-color) !important;
        }
    }
}