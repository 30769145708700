.landing-page-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .landing-page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    div {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
      opacity: 0.8;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      //   position: relative;
      //   z-index: 2;
    }
  }
  .landing-title {
    margin-bottom: 4vh;
    img {
      height: 30vh;
    }
    p {
      color: var(--background-color);
      font-size: 120%;
    }
  }
  .landing-buttons {
    width: 100%;
    button {
      background-color: var(--background-color);
      color: var(--main-color);
      font-weight: 700;
      padding: 1.5vh 6vh;
      border: 1px solid var(--background-color);
      width: 14%;
      white-space: nowrap;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3) !important;
        transition: 0.3s;
        color: var(--background-color);
      }

      & > :nth-child(1) {
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
      }

      &:nth-child(2) {
        background-color: transparent;
        color: var(--background-color);
        margin-left: 20px;
      }
    }
  }
}
