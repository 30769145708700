.download-portal-main {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.73);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .download-portal-modal {
    width: 29%;
    background-color: var(--background-color);
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    img {
      height: 12vh;
      width: 12vh;
    }

    .d-p-m-body {
      width: 80%;
      .d-p-m-title {
        padding: 4vh 0;
        img {
          height: 10vh;
          width: 10vh;
          border-radius: 50%;
        }
        h4,
        p {
          margin: 0;
        }
        h4 {
          font-weight: 700;
          color: var(--main-color);
        }
        p {
          font-size: 80%;
        }
      }
      .d-p-m-list {
        width: 100%;
        .selected-option {
          width: 100%;
          display: flex;
          flex-direction: column;
          div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3vh;
            align-items: center;
            h6 {
              img {
                height: 20px;
                width: 20px;
                margin-right: 10px;
              }
              &:first-child {
                font-size: 90%;
                font-weight: 600;
              }
              &:last-child {
                width: 50%;
                border: 1px solid var(--border-color);
                padding: 8px 20px;
                font-size: 90%;
                text-align: left;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
        .download-loading {
          height: 30vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .format-list-main {
          display: flex;
          justify-content: space-between;
          align-content: flex-start;
          flex-wrap: wrap;
          .option-wrapper {
            width: 26%;
            div {
              width: 100%;
              aspect-ratio: 1;
              border: 1px solid var(--border-color);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 15px;
              &:hover {
                background-color: var(--light-border-color);
                cursor: pointer;
              }
              img {
                height: 45%;
                width: 45%;
              }
            }
            .disable-it {
              pointer-events: none;
              img {
                opacity: 0.25;
              }
            }
            p {
              margin: 10px 0;
              font-size: 80%;
              font-weight: 600;
            }
          }
        }
        .download-type-asset {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 3vh 0;
          button {
            width: 45%;
            margin-bottom: 3vh;
            font-size: 85%;
            padding: 8px;
            background-color: transparent;
            border: 1px solid var(--border-color);
            font-weight: 600;
            // &:nth-child(2), &:nth-child(3){
            //     opacity: 0.25;
            //     pointer-events: none;
            // }
            img {
              height: 20px;
              width: 20px;
              margin-right: 5px;
            }
          }
        }
        .download-app-list {
          height: 35vh;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 0;
          }
          .r-app-wrapper {
            width: calc(100% / 3);
            display: flex;
            &:nth-child(3n + 1) {
              justify-content: flex-start;
            }
            &:nth-child(3n + 2) {
              justify-content: center;
            }
            &:nth-child(3n + 3) {
              justify-content: flex-end;
            }
            .r-app-container {
              width: 75%;
              div {
                width: 100%;
                aspect-ratio: 1;
                border: 1px solid var(--border-color);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                img {
                  height: 40%;
                  width: 40%;
                }
                &:hover {
                  cursor: pointer;
                  background-color: var(--light-border-color);
                }
              }
              p {
                font-size: 80%;
                margin: 10px 0;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .d-p-m-footer {
      height: 50px;
      display: flex;
      width: 100%;
      button,
      a {
        width: 50%;
        height: 100%;
        font-weight: 600;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(1) {
          background-color: transparent;
          border-top: 1px solid var(--border-color);
        }
        &:nth-child(2) {
          background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
          color: var(--background-color);
        }
      }
    }
  }
}
