.accounts-loading {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #7b69be 0%, #44a49d 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 29%;
    border-radius: 35px;
    background-color: var(--border-color);
    animation: glow-dim 1.5s infinite;
    height: 60vh;
  }
}
