.login-page-main {
  width: 100%;
  height: 100%;
  background: #44e2c2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .d-none {
    display: none;
  }
  .login-back {
    position: absolute;
    left: 5%;
    top: 5%;
    background-color: transparent;
    border: 0;
    color: var(--background-color);
    font-weight: 600;
    &:hover {
      cursor: pointer;
      background-color: rgba($color: #ffffff, $alpha: 0.25);
      transition: 0.3s;
    }
  }
  .login-admin {
    position: absolute;
    right: 5%;
    top: 5%;
    background-color: transparent;
    border: 0;
    color: var(--background-color);
    font-weight: 600;
    &:hover {
      cursor: pointer;
      background-color: rgba($color: #ffffff, $alpha: 0.25);
      transition: 0.3s;
    }
  }
}
