.dashboard-main {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  overflow: hidden;
  .d-none {
    display: none;
  }
  .add-app-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba($color: #000000, $alpha: 0.7);
  }
  .dashboard-header {
    display: flex;
    height: 80px;
    border-bottom: 1px solid var(--border-color);
    animation: from-right 0.3s 1 linear;
    .d-h-title {
      width: 18%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid var(--border-color);
      &:hover {
        background-color: var(--light-border-color);
        cursor: pointer;
      }
      img {
        height: 80%;
        cursor: pointer;
      }
    }
    .d-h-tabs {
      width: calc(82% - 80px);
      display: flex;
      .d-h-tabs-wrapper {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }
        div {
          width: auto;
          height: 100%;
          flex: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid var(--border-color);
          cursor: pointer;
          position: relative;
          padding: 0 4vh;
          .cancel {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            height: 20px;
            width: 20px;
            &:hover {
              transform: scale(1.4);
              transition: 0.3s;
            }
          }
          h4 {
            opacity: 0.25;
            margin: 0;
            font-weight: 700;
            transition: 0.3s;
            .main-image {
              height: 20px;
              width: 20px;
              margin-right: 5px;
            }
          }
        }
        .selected-tab {
          h4 {
            opacity: 1;
            transition: 0.3s;
          }
        }
      }
    }
    .d-h-add {
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid var(--border-color);
      cursor: pointer;
      &:hover {
        img {
          transform: scale(1.2);
          transition: 0.3s;
        }
      }
      img {
        height: 25px;
        width: 25px;
      }
    }
  }
  .dashboard-sub-header {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    animation: from-left 0.3s 1 linear;
    .dashboard-sub-header-wrapper {
      width: calc(100% - 160px);
      display: flex;
      overflow: auto;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
      div {
        width: auto;
        padding: 0 4vh;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        flex: none;
        cursor: pointer;
        border-right: 1px solid var(--border-color);
        position: relative;
        .cancel {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          &:hover {
            transform: scale(1.4);
            transition: 0.3s;
          }
        }
        .main-image {
          height: 20px;
          width: 20px;
          opacity: 0.25;
        }
        h6 {
          margin-bottom: 0;
          margin-left: 10px;
          opacity: 0.25;
          white-space: nowrap;
          display: flex;
          align-items: center;
          p {
            border: 1px solid var(--border-color);
            margin: 0;
            margin-left: 10px;
            padding: 8px;
            position: relative;
            &:hover {
              transform: scale(1.1);
              transition: 0.3s;
            }
            img {
              height: 15px;
              width: 15px;
              margin-right: 5px;
            }
            .cancel {
              height: 10px;
              width: 10px;
              top: 2px;
              right: 2px;
            }
          }
          button {
            margin-left: 10px;
            background-color: transparent;
            border: 1px solid var(--border-color);
            img {
              height: 10px;
              width: 10px;
            }
            &:hover {
              transform: scale(1.2);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
      .selected-sub-tab {
        h6,
        img {
          opacity: 1;
        }
      }
    }
    .dashboard-sub-header-options {
      width: 160px;
      button {
        height: 100%;
        width: 50%;
        background-color: transparent;
        border: 0;
        border-left: 1px solid var(--border-color);
        &:hover {
          img {
            transform: scale(1.2);
            transition: 0.3s;
          }
        }
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .dashboard-sub-body {
    height: calc(100% - 140px);
    display: flex;
    .dashboard-main-table {
      width: 100%;
      height: 100%;
      transition: 0.3s;
    }
    .half-main-table {
      width: 50%;
      transition: 0.3s;
    }
    .dashboard-split-table {
      width: 0;
      height: 100%;
      overflow: hidden;
    }
    .full-split-table {
      width: 50%;
      transition: 0.3s;
    }
  }
}

@keyframes from-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes from-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
