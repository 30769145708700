@import "src/variable.scss";
.App {
  text-align: center;
  display: flex;
  height: 100vh;
  overflow: hidden;
  input,
  button {
    &:focus {
      outline: none;
    }
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
p {
  color: var(--text-color);
}
.center-it {
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-size-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
