.normal-login {
  width: 30%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vh 0;

  border-radius: 35px;
  .normal-login-title {
    width: 68%;
    margin-bottom: 9vh;
    img {
      height: 8rem;
    }
  }
  .normal-login-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 6vh;
    border-bottom: 1px solid var(--border-color);
    p {
      margin: 0;
    }
    input {
      background-color: transparent;
      border: 0;
      padding: 0.6rem 0rem;
      margin-top: 0.3rem;

      &::placeholder {
        font-size: 0.85rem;
      }
    }
    &:nth-child(3) {
      margin-bottom: 9vh;
    }
  }
  .normal-login-input {
    width: 68%;
    p {
      font-size: 90%;
    }
    input {
      width: 100%;
    }
  }
  .normal-login-last {
    width: 68%;
    button {
      width: 100%;
      padding: 0.8rem 0;
      background: #44e2c2;
      border: 0;
      border-radius: 6px;
      color: var(--background-color);
      font-weight: 600;

      &:disabled {
        opacity: 0.25;
      }

      &:hover {
        opacity: 0.85;
      }
    }
    p {
      margin: 0;
      font-size: 0.9rem;
      margin-top: 1rem;
      font-weight: 400;
      cursor: pointer;
      transition: all linear 0.2s;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
